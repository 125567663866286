
import React from 'react';
import PropTypes from 'prop-types';
import {omit} from 'ramda';


/**
 * Bdi is a wrapper for the <bdi> HTML5 element.
 * For detailed attribute info see:
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/bdi
 */
const Bdi = (props) => {
    const extraProps = {};
    if(props.loading_state && props.loading_state.is_loading) {
        extraProps['data-dash-is-loading'] = true;
    }

     /* remove unnecessary onClick event listeners  */
    const isStatic = props.disable_n_clicks || !props.id;
    return (
        <bdi
            {...(!isStatic && {onClick:
            () => props.setProps({
                n_clicks: props.n_clicks + 1,
                n_clicks_timestamp: Date.now()
            })
            })}
            {...omit(['n_clicks', 'n_clicks_timestamp', 'loading_state', 'setProps', 'disable_n_clicks'], props)}
            {...extraProps}
        >
            {props.children}
        </bdi>
    );
};

Bdi.defaultProps = {
    n_clicks: 0,
    n_clicks_timestamp: -1,
};

Bdi.propTypes = {
    /**
     * The ID of this component, used to identify dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    'id': PropTypes.string,

    /**
     * The children of this component
     */
    'children': PropTypes.node,

    /**
     * An integer that represents the number of times
     * that this element has been clicked on.
     */
    'n_clicks': PropTypes.number,

    /**
     * An integer that represents the time (in ms since 1970)
     * at which n_clicks changed. This can be used to tell
     * which button was changed most recently.
     */
    'n_clicks_timestamp': PropTypes.number,

    /**
     * When True, this will disable the n_clicks prop.  Use this to remove
     * event listeners that may interfere with screen readers.
     */
    'disable_n_clicks': PropTypes.bool,

    /**
     * A unique identifier for the component, used to improve
     * performance by React.js while rendering components
     * See https://reactjs.org/docs/lists-and-keys.html for more info
     */
    'key': PropTypes.string,

    /**
     * A wildcard data attribute
     */
    'data-*': PropTypes.string,

    /**
     * A wildcard aria attribute
     */
    'aria-*': PropTypes.string,

    /**
     * Keyboard shortcut to activate or add focus to the element.
     */
    'accessKey': PropTypes.string,

    /**
     * Often used with CSS to style elements with common properties.
     */
    'className': PropTypes.string,

    /**
     * Indicates whether the element's content is editable.
     */
    'contentEditable': PropTypes.string,

    /**
     * Defines the text direction. Allowed values are ltr (Left-To-Right) or rtl (Right-To-Left)
     */
    'dir': PropTypes.string,

    /**
     * Defines whether the element can be dragged.
     */
    'draggable': PropTypes.string,

    /**
     * Prevents rendering of given element, while keeping child elements, e.g. script elements, active.
     */
    'hidden': PropTypes.oneOfType([
        PropTypes.oneOf(['hidden', 'HIDDEN']),
        PropTypes.bool
     ]),

    /**
     * Defines the language used in the element.
     */
    'lang': PropTypes.string,

    /**
     * Defines an explicit role for an element for use by assistive technologies.
     */
    'role': PropTypes.string,

    /**
     * Indicates whether spell checking is allowed for the element.
     */
    'spellCheck': PropTypes.string,

    /**
     * Defines CSS styles which will override styles previously set.
     */
    'style': PropTypes.object,

    /**
     * Overrides the browser's default tab order and follows the one specified instead.
     */
    'tabIndex': PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
     ]),

    /**
     * Text to be displayed in a tooltip when hovering over the element.
     */
    'title': PropTypes.string,

    /**
     * Object that holds the loading state object coming from dash-renderer
     */
    'loading_state': PropTypes.shape({
        /**
         * Determines if the component is loading or not
         */
        is_loading: PropTypes.bool,
        /**
         * Holds which property is loading
         */
        prop_name: PropTypes.string,
        /**
         * Holds the name of the component that is loading
         */
        component_name: PropTypes.string,
    }),

    /**
     * Dash-assigned callback that gets fired when the element is clicked.
     */
    'setProps': PropTypes.func
};

export default Bdi;
